import '../style/setting.css';
import '../style/fonts.css';
import '../style/admin.css';
import 'bootstrap-icons/font/bootstrap-icons.min.css';
import {useEffect,useState} from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';

const AdminChatroom = () => {
    const [contacts,setContacts] = useState([]);
    const navigate = useNavigate();

    useEffect(()=>{
        axios.post(`${process.env.REACT_APP_BASE_URL}/controller/all-contacts`)
        .then(response=>{
            if(response.status === 203){
                alert(response.data.err);
            }
            setContacts(response.data);
        })
    },[])

    const openAdminChating = (username,pictureName) => {
        navigate('/adminChatting',{state:{
            username,
            pictureName
        }});
    }

    const backToBeforePage = () => {
        navigate('/admin',{replace:true});
    }

    return(
        <div id='admin' className='fcfs aic'>
            <i className='bi bi-arrow-left fs24 ml5 l0pa' onClick={backToBeforePage}></i>
            <div className='mt30'>
                {contacts.map((index,key)=>{
                    return(
                        <div className='userChatContainer mt10 frfs aic' 
                        key={key} 
                        onClick={()=>{
                            openAdminChating(index.username,index.pictureName)
                        }}
                        >
                            <div 
                                className='acProfilePicture ml10' 
                                style={{
                                    backgroundImage:`url(uploads/${index.pictureName})`
                                }}
                            ></div>
                            <div className='ml10'>@{index.username}</div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default AdminChatroom;