import './style/index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import reportWebVitals from './reportWebVitals';
import PrivateRoute from './components/PrivateRoutes';
import App from './components/App';
import Login from './components/Login';
import Register from './components/Register';
import Chatroom from './components/Chatroom';
import Comment from './components/Comment';
import Test from './components/Test';
import PrivateAdminRoute from './components/PrivateAdminRoute';
import LoginAdmin from './components/LoginAdmin';
import Admin from './components/Admin';
import AdminChatroom from './components/AdminChatroom';
import AdminComment from './components/AdminComment';
import AdminChatting from './components/AdminChatting';
import {io} from 'socket.io-client';

const socket = io(process.env.REACT_APP_BASE_URL,{
  autoConnect:true
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>

      <Route path="/" element={
        <PrivateRoute>
          <App/>
        </PrivateRoute>
      }></Route>

      <Route path="/admin" element={
        <PrivateAdminRoute>
          <Admin/>
        </PrivateAdminRoute>
      }></Route>

      <Route path='/adminChatroom' element={
        <PrivateAdminRoute>
          <AdminChatroom/>
        </PrivateAdminRoute>
      }></Route>

      <Route path='/adminComment' element={
        <PrivateAdminRoute>
          <AdminComment/>
        </PrivateAdminRoute>
      }></Route>

      <Route path='/adminChatting' element={
        <PrivateAdminRoute>
          <AdminChatting socket={socket}/>
        </PrivateAdminRoute>
      }></Route>

      <Route path="/login" element={
        <Login/>
      }></Route>

      <Route path="/loginAdmin" element={
        <LoginAdmin/>
      }></Route>

      <Route path="/register" element={
        <Register/>
      }></Route>

      <Route path='/chatroom' element={
        <PrivateRoute>
          <Chatroom socket={socket}/>
        </PrivateRoute>
      }></Route>

      <Route path='/comment' element={
        <PrivateRoute>
          <Comment/>
        </PrivateRoute>
      }></Route>

      <Route path='/test' element={
        <PrivateRoute>
          <Test/>
        </PrivateRoute>
      }></Route>

    </Routes>
  </BrowserRouter>
);

reportWebVitals();
