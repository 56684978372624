import '../style/setting.css';
import '../style/fonts.css';
import '../style/chatroom.css';
import 'bootstrap-icons/font/bootstrap-icons.min.css';
import {useEffect,useState,useRef} from 'react';
import {useNavigate,useLocation} from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';

function AdminChatting({socket}){
    const body = useRef('');
    const [contactName,setContactName] = useState('');
    const [contactPicture,setContactPicture] = useState('');
    const [messages,addMessages] = useState([]);
    const navigate = useNavigate();
    const {state} = useLocation();
    const [i,setI] = useState(0);

    const backToBeforePage = () => {
        navigate('/adminChatroom',{replace:true});
    }

    useEffect(() => {
        body.current.scrollTo(0,body.current.scrollHeight);

        if(state.username === '' ||state.pictureName === ''){
            navigate('/adminChatroom',{replace:true});
        }

        setContactName(state.username);
        setContactPicture(state.pictureName);

        async function queryMessages(){
            const formdata = new FormData();
            formdata.append('from','admin');
            formdata.append('to',state.username);
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/controller/query-messages`,formdata);
            addMessages([...response.data]);
        }

        if(messages.length === 0 && (i===0 || i === 1)){
            queryMessages()
        }

        setI(i+1);
      
        socket.on('connect', () => {
          socket.emit('online',{
            token:sessionStorage.getItem('token')
          })
        });
      
        socket.on('message', (data) => {
            console.log(data);
            if(data.username===state.username){
                data.realTime = true
                addMessages([...messages,data]);
            }
        });

    }, [socket,messages]);

    const sendingMessage = e => {
        const token = jwtDecode(sessionStorage.getItem('token'));
        e.preventDefault();

        const input = e.target.querySelector('input');
        addMessages([...messages,{
            pictureName:token.pictureName,
            message:input.value,
            realTime:true,
            from:'admin'
        }])
        console.log(messages);

        socket.emit('message',{
            token:sessionStorage.getItem('token'),
            message:input.value,
            to:state.username,
            from:'admin'
        })

        input.value = '';
    }

    return(
        <div className='chatroomCCC'>
            <div className='chatroomContainer'>
                <div className='header aic frfs'>
                    <i className='bi bi-arrow-left fs24 ml5' onClick={backToBeforePage}></i>
                    <div 
                        className='profilePictureChatroom ml5'
                        style={{
                            backgroundImage:`url(uploads/${contactPicture})`
                        }}
                        ></div>
                    <div className='usernameChatroom ml5'>@{contactName}</div>
                </div>
                <div className='body' ref={body}>
                    <div className='layer'></div>
                    {messages.map((index,key)=>{
                        if(index.from==='admin'){
                            return(
                                <SendMessage key={key} message={index.message}/>
                            )
                        }else{
                            if(index.realTime){
                                return(
                                    <RecieveMessage key={key} message={index.message} username={index.username} pictureName={index.pictureName}/>
                                )
                            }else{
                                return(
                                    <RecieveMessage key={key} message={index.message} username={index.username} pictureName={index.reference.pictureName}/>
                                )
                            }
                        }
                    })}
                </div>
                <form className='footer frc aic' onSubmit={sendingMessage}>
                    <input className='messageInput' type='text' placeholder='message ...'/>
                    <button className='messageBtn' type='submit'>send</button>
                </form>
            </div>
        </div>
    )
}

export default AdminChatting;

const SendMessage = ({message}) => {
    
    return(
        <div className='frfe w100 mt-15'>
            <div className='sendContainer mr5'>{message}</div>
            <div className='profilePictureInMessageSend mr5'></div>
        </div>
    )
}

const RecieveMessage = ({message,username,pictureName}) => {
    return(
        <div className='frfs w100 mt-15'>
            <div 
                className='profilePictureInMessageReceive ml5'
                style={{
                    backgroundImage:`url(uploads/${pictureName})`
                }}
            ></div>
            <div className='recieveContainer ml5'>{message}</div>
        </div>
    )
}