import '../style/setting.css';
import '../style/fonts.css';
import '../style/App.css';
import '../style/icons.css';
import 'bootstrap-icons/font/bootstrap-icons.min.css';
import {useEffect,useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const showRemoveMenuContainer = e => {
  const mainContainer = document.getElementById('mainContainer');
  const menuContainer = document.getElementById('menuContainer');
  const lines = menuContainer.querySelectorAll('.line');
  const linesT = mainContainer.querySelectorAll('.line');

  if(mainContainer.classList.contains('newClass')){
    mainContainer.classList.remove('newClass');
    menuContainer.style.height = '0';
  }else{
    mainContainer.classList.add('newClass');
    menuContainer.style.height = '100vh';
  }

  if(lines[1].classList.contains('dsn')){
    lines[0].classList.remove('l0');
    lines[1].classList.remove('dsn');
    lines[2].classList.remove('l2');

    linesT[0].classList.remove('l0');
    linesT[1].classList.remove('dsn');
    linesT[2].classList.remove('l2');
  }else{
    lines[0].classList.add('l0');
    lines[1].classList.add('dsn');
    lines[2].classList.add('l2');

    linesT[0].classList.add('l0');
    linesT[1].classList.add('dsn');
    linesT[2].classList.add('l2');
  }
}

function App() {
  return (
    <>
      <div id="smooth-wrapper">
        <div id="smooth-content">
          <Container/>
          <MenuContainer/>
        </div>
      </div>
    </>
  );
}

//todo-> view port of main container:
const Container = () => {

  return(
    <div className='pa' id='mainContainer'>
      
      <MainPicture/>

      <BioComponent/>

      {/* 
      //! intreduse my skills: 
      */}
      <div id='section2'>
        <br/>
        <div className='mt20 ml5 ttc intreduce mySkillIntreduce mt15'>my skills:</div>

        <SkillComponents/>

      </div>

      <BigProjectsUi/>

      <Resumes/>

      <CommentComponents/>
    </div>
  )
}

//todo-> main picture in first page ui:
const MainPicture = () => {
  const [isPlay,setIsPlay] = useState(false);

  const playMusic = e => {
    if(!isPlay){
      e.target.classList.remove('bi-play');
      e.target.classList.add('bi-pause');

      const music = new Audio();
      music.src = 'music/music.mp3';
      music.play();
    }

    setIsPlay(true);
  }

  const ref = useRef([]);
  ref.current = [];

  useEffect(() => {
    ref.current.forEach((el) => {
      gsap.fromTo(el, {webkitTextStroke:"2px rgb(0,200,255)"}, {
        yoyo:true,webkitTextStroke:"2px rgba(0,200,255,0)",duration: 0.7, 
        scrollTrigger:{
          trigger: el,
          scrub: true,
          pin: true,
          start: "center center-=20",
          end: "-=20px"
        }
      })
    })
  }, [])

  const addtoRefs = (el) => {
      if (el && !ref.current.includes(el)) {
          ref.current.push(el);
      }
  }

  {/* 
  //! showing my picture:
  */}
  return(
    <div id='myPicture' className='fcc aic'>
      <div className='threeLine fcs pa mt10 ml10 pintop' onClick={showRemoveMenuContainer}>
        <div className='line'></div>
        <div className='line'></div>
        <div className='line'></div>
      </div>
      <div id='welcome' className="frfs" ref={addtoRefs}>
        <i className='bi bi-play mt10' onClick={playMusic}></i>
        <div>welcome</div>
      </div>
    </div>
  )
}

//todo-> all skill box for gsap.js:
const SkillComponents = () => {

  const ref = useRef([]);
  ref.current = [];

  const refD = useRef([]);
  refD.current = [];

  useEffect(() => {

    ref.current.forEach((el) => {
      gsap.fromTo(el, { scale:0 }, {
        left: 0,yoyo:true,
        duration: .5,scale:1, scrollTrigger: {
          trigger: el,
          start: "bottom bottom-=30",
          toggleActions: "play none none reverse"
        }
      })
    })

    refD.current.forEach((el) => {
      gsap.fromTo(el, { width:0,backgroundColor:"red" }, {
        width:"100%",backgroundColor:"purple",yoyo:true,
        duration: 1, scrollTrigger: {
          trigger: el,
          start: "center center+=50",
          end:"+=100",
          toggleActions: "play none none reverse"
        }
      })
    })

  }, [])

  const addtoRefs = (el) => {
      if (el && !ref.current.includes(el)) {
          ref.current.push(el);
      }
  }

  const addtoRefsD = (el) => {
      if (el && !refD.current.includes(el)) {
          refD.current.push(el);
      }
  }

  const skills = [{
    skillName:"NodeJs",
    className:"commonClassImg nodejsIcon"
  },{
    skillName:"PHP",
    className:"commonClassImg phpIcon"
  },{
    skillName:"React.js",
    className:"commonClassImg reactIcon"
  },{
    skillName:"Javascript",
    className:"commonClassImg javascriptIcon"
  },{
    skillName:"HTML",
    className:"commonClassImg htmlIcon"
  },{
    skillName:"CSS",
    className:"commonClassImg cssIcon"
  },{
    skillName:"Socket.io",
    className:"commonClassImg socketIcon"
  },{
    skillName:"Express",
    className:"commonClassImg expressIcon"
  },{
    skillName:"Three.js",
    className:"commonClassImg threeIcon"
  },{
    skillName:"Gsap.js",
    className:"commonClassImg gsapIcon"
  },{
    skillName:"Jquery",
    className:"commonClassImg jqueryIcon"
  },{
    skillName:"Mongodb",
    className:"commonClassImg mongodbIcon"
  },{
    skillName:"Redis",
    className:"commonClassImg redisIcon"
  },{
    skillName:"Mysql",
    className:"commonClassImg mysqlIcon"
  },{
    skillName:"Postgresql",
    className:"commonClassImg postgresqlIcon"
  },{
    skillName:"Webxr",
    className:"commonClassImg webxrIcon"
  },{
    skillName:"Docker",
    className:"commonClassImg dockerIcon"
  },{
    skillName:"Typescript",
    className:"commonClassImg typescriptIcon"
  }];

  return(
    <div>
      {/* 
      //todo-> using skill component:
      */}
      {skills.map((index,key)=>{

        var wid = Math.random()*100;
        wid = Math.round(wid);

        return(
          <div className='skillContent mt30' ref={addtoRefs} key={key}>
            <div className='frfs skillIconAndNameContainer'>
              <div className={`${index.className} ml10`}></div>
              <div className='skillName'>{index.skillName} :</div>
            </div>
            <div className='frc mt10'>
              <div className='fix'>
                <div className='move' style={{width:`${wid}%`}} ref={addtoRefsD}></div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

//todo-> menu container:
const MenuContainer = () => {
  const navigate = useNavigate();
  
  const redirectToLogin = () => {
    navigate('/login',{replace:true});
  }

  const openChatroom = () => {
    navigate('/chatroom',{replace:true});
  }

  const openComment = () => {
    navigate('/comment',{replace:true});
  }

  const openLoginAdmin = () => {
    navigate('/loginAdmin',{replace:true});
  }

  return(
    <div className='pa menuContainer frc aic' id='menuContainer'>
      <div className='threeLine fcs pa mt10 ml10 tl0' id='selectBtn' onClick={showRemoveMenuContainer}>
        <div className='line'></div>
        <div className='line'></div>
        <div className='line'></div>
      </div>
      <div className='fcs aic' id='optionsContainer'>
        <div className='option' onClick={redirectToLogin}>Login</div>
        <div className='option' onClick={openChatroom}>Chatroom</div>
        <div className='option' onClick={openComment}>Comment</div>
        <div className='option' onClick={openLoginAdmin}>login admin</div>
      </div>
    </div>
  )
}

//todo-> my bio component:
const BioComponent = () => {
  const [bioTextWidth,setBioTextWidth] = useState(7890);

  const bio = "Hello, nice to meet you, my name is M.Javad Shekarian.\n\n I met programming seven years ago and because of great interest I was coding along with studying, but four years ago a wonderful idea came to my mind and this made me continue coding with much, much more strength. At first I learned php completely, but I decided to learn NodeJs because I needed a real-time language. In these four years, I gained unique expertise in the field of web coding and i worked in different companies, including Kasbino and Akaf, and then, along with working in companies, I also worked as a freelancer, and I am very happy with the path I have chosen, and And it gives me a good feeling that I have chosen a job that I am interested in and It makes me feel useful!"

  const ref = useRef([]);
  ref.current = [];

  window.onscroll = () => {
    const bioText = document.getElementById('bioText');
    bioText.style.marginLeft = -window.scrollY + window.innerHeight +100 + 'px';

    if(window.scrollY>window.innerHeight && window.scrollY<bioTextWidth + window.innerHeight){
      var n = (bioTextWidth - (window.scrollY - window.innerHeight))
      n = (n/bioTextWidth)*255;
      n = 255 - n;
      
      const body = document.body;
      body.style.backgroundColor = `rgb(${n},${n},${n})`;
    }

    if(window.scrollY>bioTextWidth + window.innerHeight){
      document.body.style.backgroundColor = 'white';
    }

    // const littleSkillBegin = bioTextWidth + window.innerHeight;

    // if(window.scrollY>bioTextWidth + window.innerHeight && window.scrollY<littleSkillBegin + 50){
    //   document.body.style.backgroundColor = 'white';
    // }

    // if(window.scrollY>littleSkillBegin && window.scrollY < 18261){
    //   var colorStep = window.scrollY - littleSkillBegin;
    //   colorStep = colorStep/1300;
    //   colorStep = colorStep*255;
    //   colorStep = 255 - colorStep;
      
    //   var bColor = `rgb(${colorStep},${colorStep},${colorStep})`;
    //   document.body.style.backgroundColor = bColor;
    // }
    // if(window.scrollY>18261){
    //   document.body.style.backgroundColor = 'black'
    // }
  }

  window.onresize = () =>{
    const setWidth = (window.innerWidth>768)?16421:7890;
    setBioTextWidth(setWidth);
  }

  useEffect(() => {
    const setWidth = (window.innerWidth>768)?16421:7890;
    setBioTextWidth(setWidth);

    ref.current.forEach((el) => {
      gsap.fromTo(el, { y: window.innerHeight/4,color:'rgb(0,200,255)' }, {
        y:(3*window.innerHeight)/4,color:'blue', 
        left: 0,yoyo:true,duration: 0.7, 
        scrollTrigger:{
          trigger: el,
          scrub: true,
          pin: true,
          start: "center center",
          end: `+=${setWidth}px`
        }
      })
    })
  }, [])

  const addtoRefs = (el) => {
      if (el && !ref.current.includes(el)) {
          ref.current.push(el);
      }
  }

  {/* 
  //! bio section: 
  */}
  return(
    <div id='mmContainer' ref={addtoRefs}>
      <div id='mContainer'>
        <div id='bioText'>{bio}</div>
      </div>
    </div>
  )
}

//todo-> my big projects commponent and ui:
const BigProjectsUi = () => {

  const ref = useRef([]);
  ref.current = [];

  useEffect(() => {
    ref.current.forEach((el) => {
      gsap.fromTo(el, { autoAlpha: 0 }, {
        autoAlpha: 1,yoyo:true,duration: 0.7, 
        scrollTrigger:{
          trigger: el,
          scrub: true,
          pin: true,
          start: "top top",
          end: "+=100%"
        }
      })
    })

    // ref.current.forEach((el) => {
    //   gsap.fromTo(el, {autoAlpha:0}, {
    //     yoyo:true,autoAlpha:1,duration: 1, 
    //     scrollTrigger:{
    //       trigger: el,
    //       scrub: true,
    //       pin: true,
    //       start: "top top",
    //       end: "-=20px"
    //     }
    //   })
    // })
  }, [])

  const addtoRefs = (el) => {
      if (el && !ref.current.includes(el)) {
          ref.current.push(el);
      }
  }

  const mainProjects = [
    {
      parentClassName:"imgBackgroundPic1",
      className:"bi bi-messenger",
      projectName:"messenger",
      usedSkills:["docker","nginx","nodeJs","javascript","react.js","html","css","peerConnection","socket.io","mysql"],
      explain:"I coded a messenger last year. The coding of this messenger took about six months. This messenger has the following features: sending messages, sending files, video calls, creating channels and groups, saving messages, voice calls and the ability to send photos and voice."
    },{
      parentClassName:"imgBackgroundPic2",
      className:"bi bi-translate",
      projectName:"Automatic speech translator",
      usedSkills:["nodeJs","javascript","html","css","peerConnection","socket.io","mongodb"],
      explain:"In this project a person makes a video call with one or more people, and the important feature of this video call is a speech translator, which means that users may have different languages, but this app translates all languages ​​into the user's language by speech"
    },{
      parentClassName:"imgBackgroundPic3",
      className:"bi bi-instagram",
      projectName:"Localized Instagram",
      usedSkills:["docker","nginx","nodeJs","redis","typescript","javascript","jquery","mvc Structure","react.js","mongodb","html","css","peerConnection","socket.io","mysql"],
      explain:"I coded this project about four years ago. This project has all the options of Instagram, of course, I did not make it online due to the lack of financial resources, but I plan to make it online soon."
    },{
      parentClassName:"imgBackgroundPic4",
      className:"bi bi-globe",
      projectName:"social ar",
      usedSkills:["docker","nginx","nodeJs","react.js","@imgly/background-removal","@imgly/background-removal-node","rembg","three.js","webxr","mvc Structure","jquery","redis","quick look","javascript","html","css","peerConnection","socket.io","mysql","rabbitmq"],
      explain:"In this project, the user takes a photo of the object or uploads a photo, then the app automatically removes the background and the user can see the object in the real world (AR). Also, this app has the ability to send messages and posts, and the user can send the object to others using the messenger, and others can see the same object in the real world. Also, this app has other features, for example: the ability to follow or be followed, the ability to like a post, the ability to comment on a post, the ability to remove complex background for all objects and people, the ability to remove background chroma key, and the ability to searching for people's accounts or searching for a specific photo"
    },{
      parentClassName:"imgBackgroundPic5",
      className:"bi bi-badge-ar",
      projectName:"AR",
      usedSkills:["nodeJs","react.js","three.js","webxr","@imgly/background-removal","@imgly/background-removal-node","rembg","hit testing","mongodb","HTML","css"],
      explain:"My latest project is the AR project. I started this project four months ago and finished the first version a few days ago. I did this project for an American employer. The features of this project are:1. Removing complex background of all kinds of objects and people using AI2. Convert png format to gltf and usdz using three.js3. Display the object in the real world using webxr on Android and e quick look on iOS4. The ability to share the link so that others can see the object in the real world"
    }
  ];

  {/* 
  //!-> intreduse my big projects to future employers:
  */}
  return(
    <div id='myBigProjects' className='mt20'>

      {/* 
      //todo-> using big project component:
      */}
      {mainProjects.map((index,key)=>{
        return(
          <div className={`main fcc h100vw ml5 ${index.parentClassName}`} key={key} ref={addtoRefs}>
            {(index.projectName==='messenger')
              ?
              <div className='mt20 ml5 ttc intreduce pat0'>my big projects:</div>
              :
              ''
            }
            <div className='mt10'>

              <div className='ttc ml5 bb3 frfs'>
                <i className={`${index.className}`}></i>
                <div className='ml5'>{index.projectName}</div>
              </div>

              {(index.projectName.toLocaleLowerCase()==='social ar')
              ?
              <div className='projectExplain ml5 fs14'>{index.explain}</div>
              :
              <div className='projectExplain ml5'>{index.explain}</div>
              }
            </div>

            <div className='usedSkills mt10 ttc frfs'>
              <i className="bi bi-wrench"></i>
              <div className='ml5'> used skills:</div>
            </div>

            <div className='ttc wid_98'>
              {index.usedSkills.map((skill,key)=>{
                return(
                  <div className='usedSkillName fs12 ml5' key={key}>#{skill}</div>
                )
              })}
            </div>
          </div>
        )
      })}

    </div>
  )
}

//todo-?> my resume sections:
const Resumes = () => {

  const ref = useRef([]);
  ref.current = [];

  useEffect(() => {
    ref.current.forEach((el) => {
      gsap.fromTo(el, { autoAlpha: 0 }, {
        autoAlpha: 1, left: 0,yoyo:true,duration: 0.7, 
        scrollTrigger:{
          trigger: el,
          scrub: true,
          start: "top bottom",
          end: "top top"
        }
      })
    })
  }, [])

  const addtoRefs = (el) => {
      if (el && !ref.current.includes(el)) {
          ref.current.push(el);
      }
  }

  const resumeJson = [
    {
      num:"One",
      companyName:"Kasbino",
      workDuration:"6Month",
      fields:["NodeJs","React.js","JavaScript","JQuery"]
    },{
      num:"Two",
      companyName:"Akaf",
      workDuration:"3Month",
      fields:["NodeJs","TypeScript"]
    },{
      num:"Three",
      companyName:"Kaya",
      workDuration:"2years",
      fields:["NodeJs","React.js","JavaScript","JQuery","Three.js","PHP","Socket.io"]
    },
  ]

  {/* 
  //! resume section: 
  */}
  return(
    <div id='mainResume'>
      {resumeJson.map((index,key)=>{
        return(
          <div className='ttc mt50 ml10 fflb mmt0i' key={key} ref={addtoRefs}>
      
            {(index.num.toLocaleLowerCase() === 'one')
            ?
            <div className='mt-50is'>
              <div className='mt20 ml5 ttc intreduce'>my resume:</div>
              <br/><br/>
            </div>
            :
            ''
            }
      
            <span className='odn ttc'>{index.num}</span>
      
            <span className='ttc'>
              Company Name: 
              <span className='fflr'>{index.companyName}</span>
            </span>
      
            <div className='ttc'>
              Duration Of Work: 
              <span className='fflr'>{index.workDuration}</span>
            </div>
      
            <div>
              Field of work:&nbsp;&nbsp;
              {index.fields.map((field,key)=>{
                const x = index.fields.length;
                const t = ((key+1)!==x)?',':'';
      
                return(
                  <span className='fflr' key={key}>{field}{t} </span>
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}

//todo-> comment components:
const CommentComponents = () => {

  const ref = useRef([]);
  ref.current = [];

  useEffect(() => {
    ref.current.forEach((el) => {
      gsap.fromTo(el, { autoAlpha: 0 }, {
        autoAlpha: 1, left: 0,yoyo:true,duration: 0.7, 
        scrollTrigger:{
          trigger: el,
          start: "left right-=100",
          toggleActions: "play none none reverse"
        },
        rotate:360
      })
    })
  }, [])

  const addtoRefs = (el) => {
      if (el && !ref.current.includes(el)) {
          ref.current.push(el);
      }
  }

  const comments = [
    {
      picture:"pic1.jpg",
      username:"Sara.H",
      comment:"My latest project is the AR project. I started this project"
    },{
      picture:"pic2.jpg",
      username:"Sara.H",
      comment:"In this project, the user takes a photo of the object or uploads a photo, then the app automatically"
    },{
      picture:"pic3.jpg",
      username:"Milad.Ehhh",
      comment:"I coded this project about four years ago. This project has all the options of Instagram, of course, I did not make it online due to the lack of financial resources, but I plan to make it online soon."
    },{
      picture:"pic4.jpg",
      username:"Sahar.Nor",
      comment:"I coded this project about four years ago. This project has all the options of Instagram, of course, I did not make it online due to the lack of financial resources, but I plan to make it online soon."
    },{
      picture:"pic5.jpg",
      username:"Hadis",
      comment:"I coded a messenger last year. The coding of this messenger took about six months. This messenger has the "
    },{
      picture:"pic6.jpg",
      username:"Ebrahim",
      comment:"I coded a messenger last year. The coding of this messenger took about six months. This messenger has the "
    },{
      picture:"pic7.jpg",
      username:"Milad.Ehhh",
      comment:"I coded this project about four years ago. This project has all the options of Instagram, of course, I did not make it online due to the lack of financial resources, but I plan to make it online soon."
    },{
      picture:"pic8.jpg",
      username:"Sara.H",
      comment:"My latest project is the AR project. I started this project"
    },{
      picture:"pic9.jpg",
      username:"Sahar.Nor",
      comment:"I coded this project about four years ago. This project has all the options of Instagram, of course, I did not make it online due to the lack of financial resources, but I plan to make it online soon."
    },{
      picture:"pic3.jpg",
      username:"Milad.Ehhh",
      comment:"I coded this project about four years ago. This project has all the options of Instagram, of course, I did not make it online due to the lack of financial resources, but I plan to make it online soon."
    }
  ]

  return(
    <div id='commentContainer' className='mt40'>
      <div className='mt20 ml5 ttc intreduce'>Comments:</div>
      <div id='commentBoxDirection'>
        {comments.map((index,key)=>{
          return(
            <div className='commentContent mt5 pr' key={key} ref={addtoRefs}>
              <div className='frfs aic'>
                <div className='profilePic' style={{
                  backgroundImage:`url(pic/${index.picture})`
                }}></div>
                <div className='ml5 fflb'>{index.username}</div>
              </div>
              <div className='fflr fs14'>{index.comment}</div>
              <div className='pa heart'></div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default App;