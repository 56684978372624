import '../style/setting.css';
import '../style/fonts.css';
import '../style/login.css';
import 'bootstrap-icons/font/bootstrap-icons.min.css';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';

function Login(){
    return(
        <div>
            <div id='bgi' className='pa'></div>
            <LoginForm/>
        </div>
    )
}

const LoginForm = () => {
    const navigate = useNavigate();

    const registerPage = () => {
        navigate('/register',{replace:true});
    }

    const backToHome = () => {
        navigate('/',{replace:true});
    }

    const loginFunc = async e => {
        const errorSection = document.getElementById('errorSection');
        
        e.preventDefault();
        
        const inputs = e.target.querySelectorAll('input');
        
        const formdata = new FormData();
        formdata.append('username',inputs[0].value);
        formdata.append('password',inputs[1].value);

        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/controller/login`,formdata);

        if(response.status === 203){
            errorSection.style.opacity = '100%';
            errorSection.innerHTML = response.data.message;
        }

        if(response.status === 200 ){
            sessionStorage.setItem('token',response.data);
            navigate('/',{replace:true});
        }
    }

    const cleanInput = () => {
        const errorSection = document.getElementById('errorSection');
        errorSection.style.opacity = '0%';
    }

    return(
        <form className='fcc aic pa' onSubmit={loginFunc}>
            <i className='bi bi-arrow-left fs24 cw pa tl0 mt5 ml5' onClick={backToHome}></i>
            <div className='containerInps fcc aic'>
                <div className='w280 frc int w80'>Login</div>
                <input onChange={cleanInput} className='loginInput' placeholder='Enter Username'/>
                <input onChange={cleanInput} className='loginInput' type='password' placeholder='Enter Password'/>
                <div className='fcfs w280'>
                    <div id='errorSection'>this part is for errors</div>
                    <div className='registerText' onClick={registerPage}>register page</div>
                </div>
                <div className='frfe w280'>
                    <button type='submit' className='loginBtn'>login</button>
                </div>
            </div>
        </form>
    )
}

export default Login;