import '../style/setting.css';
import '../style/fonts.css';
import '../style/comment.css';
import '../style/login.css';
import 'bootstrap-icons/font/bootstrap-icons.min.css';
import {useEffect,useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';
import axios from 'axios';

function Comment(){
    const profilePicture = useRef();

    useEffect(()=>{
        const token = jwtDecode(sessionStorage.getItem('token'));
        const filePath = `uploads/${token.pictureName}`;
        profilePicture.current.style.backgroundImage = `url(${filePath})`;
    },[])

    const navigate = useNavigate();

    const backToHome = () => {
        navigate('/',{replace:true});
    }

    const addComment = async e => {
        const errorSection = document.getElementById('errorSection');

        e.preventDefault();
        
        const textarea = e.target.querySelector('textarea');

        const formdata = new FormData();
        formdata.append('comment',textarea.value);
        formdata.append('token',sessionStorage.getItem('token'));

        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/controller/addComment`,formdata);

        if(response.status === 203){
            errorSection.style.opacity = '100%';
            errorSection.innerHTML = response.data.error.details[0].message;
        }

        if(response.status === 200){
            alert(response.data.message);
        }
    }

    const clearTextarea = () => {
        const errorSection = document.getElementById('errorSection');
        errorSection.style.opacity = '0%';
    }

    return(
        <form onSubmit={addComment}>
            <div id='commentContainer' className='fcc aic'>
                <i className='clg bi bi-arrow-left fs24 tl0 mt5 ml5 pa' onClick={backToHome}></i>
                <div className='ppcc frfs aic'>
                    <div className='profilePicComment' ref={profilePicture}></div>
                    <div className='commentUsername fs18 ml5'>@username</div>
                </div>
                <textarea onChange={clearTextarea} spellCheck={false} placeholder='Enter Your Comment ...' className='mt10'></textarea>
                <div className='fcfs w280'>
                    <div id='errorSection'>this part is for errors</div>
                </div>
                <div className='w90vw frfe'>
                    <button type='submit' className='ttc mt10 commentButton'>add comment</button>
                </div>
            </div>
        </form>
    )
}

export default Comment;