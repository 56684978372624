import '../style/setting.css';
import '../style/fonts.css';
import '../style/admin.css';
import 'bootstrap-icons/font/bootstrap-icons.min.css';
import {useEffect,useState} from 'react';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';

const AdminComment = () => {
    const [comments,setComments] = useState([]);
    const navigate = useNavigate();

    useEffect(()=>{
        const formdata = new FormData();
        formdata.append('token',sessionStorage.getItem('token'));

        axios.post(`${process.env.REACT_APP_BASE_URL}/controller/query-comments`,formdata)
        .then(response=>{
            setComments(response.data);
        })
    },[])

    const backToBeforePage = () => {
        navigate('/admin',{replace:true});
    }

    return(
        <div id='admin' className='fcfs aic pt20pb20'>
            <i className='bi bi-arrow-left fs24 ml5 l0pa' onClick={backToBeforePage}></i>
            <div className='mt30'>
                {comments.map((index,key)=>{
                    return(
                        <Comment 
                            profilePicture={index.reference.pictureName} 
                            comment={index.comment}
                            comment_id={index._id}
                            isShownInPage={index.isShownInPage}
                            key={key}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default AdminComment;

const Comment = ({profilePicture,comment,comment_id,isShownInPage}) => {

    const commentShowInPage = async (event,id) => {
        var btn = event.target;
        
        if(btn.innerText === 'show in page'){
            const formdata = new FormData();
            formdata.append('comment_id',id);
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/controller/show-comment`,formdata);
            
            if(response.status===200){
                btn.innerHTML = 'showed';
            }else{
                alert(response.data)
            }
        }else{
            const formdata = new FormData();
            formdata.append('comment_id',id);
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/controller/notshow-comment`,formdata);

            if(response.status===200){
                btn.innerHTML = 'show in page';
            }else{
                alert(response.data)
            }
        }
    }

    const commentRemoveComment = async (event,id) => {
        const confirmed = window.confirm('are you sure to remove the comment?');
        if(!confirmed){
            return;
        }
        const commentBox = event.target.parentNode.parentNode;

        const formdata = new FormData();
        formdata.append('comment_id',id);

        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/controller/remove-comment`,formdata);

        if(response.status === 200){
            commentBox.remove();
        }else{
            alert(response.data);
        }
    }

    return(
        <div className='adminCommentBox mt10 fcfs'>
            <div className='frfs aic'>
                <div 
                    className='adminCommentProfilePicture ml10'
                    style={{
                        backgroundImage:`url(uploads/${profilePicture})`
                    }}
                ></div>
                <div className='ml10 acommentW'>{comment}</div>
            </div>
            <div className='frs mt15'>
                {
                    isShownInPage
                    ?
                    <button 
                        className='commentBtn' 
                        onClick={event=>commentShowInPage(event,comment_id)}
                    >showed</button>
                    :
                    <button 
                        className='commentBtn' 
                        onClick={event=>commentShowInPage(event,comment_id)}
                    >show in page</button>
                }

                <button 
                    className='commentBtn' 
                    onClick={event=>commentRemoveComment(event,comment_id)}
                >remove comment</button>
            </div>
        </div>
    )
}