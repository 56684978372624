import '../style/setting.css';
import '../style/fonts.css';
import '../style/login.css';
import 'bootstrap-icons/font/bootstrap-icons.min.css';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';

const errors = {
    usernameEmpty:'username cannot be empty. Please choose a username!',
    passwordEmpty:'password cannot be empty. Please choose a password!',
    invalidPasswords:'The passwords you entered are different. Please check the password again'
}

function Register(){
    return(
        <div>
            <div id='bgi' className='pa'></div>
            <RegisterForm/>
        </div>
    )
}

const RegisterForm = () => {
    
    const navigate = useNavigate();

    const goToLoginPage = () => {
        navigate('/login',{replace:true});
    }

    const backToHome = () => {
        navigate('/',{replace:true});
    }

    const saveInformation = async e => {
        const errorSection = document.getElementById('errorSection');

        e.preventDefault();

        const form = document.getElementById('registerForm');
        const inputs = form.querySelectorAll('input');

        const formdata = new FormData();
        formdata.append('username',inputs[0].value);
        formdata.append('password',inputs[1].value);
        formdata.append('repeatPassword',inputs[2].value);
        formdata.append('file',inputs[3].files[0])

        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/controller/register`,formdata);

        if(response.status === 203){
            errorSection.style.opacity = '100%';
            errorSection.innerHTML = response.data.error.details[0].message;
        }

        if(response.status === 200 ){
            sessionStorage.setItem('token',response.data);
        }
    }

    const cleanInput = () => {
        const errorSection = document.getElementById('errorSection');
        errorSection.style.opacity = '0%';
    }

    return(
        <form className='fcc aic pa' id='registerForm' onSubmit={saveInformation}>
            <i className='bi bi-arrow-left fs24 cw pa tl0 mt5 ml5' onClick={backToHome}></i>
            <div className='containerInps fcc aic'>
                <div className='w280 frc int'>Register</div>
                <input onChange={cleanInput} className='loginInput' type='text' placeholder='Select Username'/>
                <input onChange={cleanInput} className='loginInput' type='password' placeholder='Select Password'/>
                <input onChange={cleanInput} className='loginInput' type='password' placeholder='Repeat Password'/>
                <input onChange={cleanInput} type='file' className='loginFileInput dsn' id='loginFileInput'/>
                <label htmlFor="loginFileInput" className='loginFileInput frs aic'>
                    <div className='frfs aic'>
                        <div className='plusContent'>
                            <div className='oline pa'></div>
                            <div className='aline pa'></div>
                        </div>
                        <div className='ml10 upp ttc'>upload profile picture</div>
                    </div>
                </label>
                <div className='fcfs w280'>
                    <div id='errorSection'>this part is for errors</div>
                    <div className='registerText' onClick={goToLoginPage}>login page</div>
                </div>
                <div className='frfe w280'>
                    <button className='loginBtn' type="submit">Register</button>
                </div>
            </div>
        </form>
    )
}

export default Register;