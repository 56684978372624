import '../style/setting.css';
import '../style/fonts.css';
import '../style/admin.css';
import {useNavigate} from 'react-router-dom';

const Admin = () => {
    const navigate = useNavigate();

    const redirectAdminChatroom = () => {
        navigate('/adminChatroom',{replace:true});
    }

    const redirectComment = () => {
        navigate('/adminComment',{replace:true});
    }

    return(
        <div id='admin' className='fcc aic'>
            <div className='mt20 fs24 fflb ttc' onClick={redirectAdminChatroom}>chatroom</div>
            <div className='mt20 fs24 fflb ttc' onClick={redirectComment}>comment</div>
        </div>
    )
}

export default Admin;