import React from 'react';
import {useNavigate,Navigate} from 'react-router-dom';
import axios from 'axios';
import {useEffect} from 'react';

function PrivateAdminRoute({children}){

    const navigate = useNavigate();

    useEffect(()=>{
        (async function(){
            var x = Boolean(sessionStorage.getItem('token'));
            if(x){
                const formdata = new FormData();
                formdata.append('token',sessionStorage.getItem('token'));
                const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/controller/check-admin-information`,formdata);
                
                if(response.status === 203){
                    navigate('/loginAdmin',{replace:true});
                }
            }else{
                navigate('/loginAdmin',{replace:true});
            }
        })();
    },[])

    return children;
}

export default PrivateAdminRoute;