import '../style/setting.css';
import '../style/fonts.css';
import '../style/chatroom.css';
import 'bootstrap-icons/font/bootstrap-icons.min.css';
import {useEffect,useState,useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';
import axios from 'axios';

function Chatroom({socket}){
    const body = useRef('');
    const [messages,addMessages] = useState([]);
    const navigate = useNavigate();
    const [i,setI] = useState(0);

    const backToBeforePage = () => {
        navigate('/',{replace:true});
    }

    useEffect(() => {
        body.current.scrollTo(0,body.current.scrollHeight);
      
        socket.on('connect', () => {
          socket.emit('online',{
            token:sessionStorage.getItem('token')
          })
        });

        async function queryMessages(){
            const token = jwtDecode(sessionStorage.getItem('token'));
            const formdata = new FormData();
            formdata.append('from',token.username);
            formdata.append('to','admin');
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/controller/query-messages`,formdata);
            addMessages([...response.data]);
        }
        if(messages.length === 0 && (i===0 || i === 1)){
            queryMessages()
        }
        setI(i+1);
      
        socket.on('message', (data) => {
            console.log(data);
            addMessages([...messages,data]);
        });
    }, [socket,messages]);

    const sendingMessage = e => {
        const token = jwtDecode(sessionStorage.getItem('token'));
        e.preventDefault();

        const input = e.target.querySelector('input');

        addMessages([...messages,{
            username:token.username,
            pictureName:token.pictureName,
            message:input.value,
            from:token.username
        }])

        socket.emit('message',{
            token:sessionStorage.getItem('token'),
            message:input.value,
            to:'admin',
            from:token.username
        })

        input.value = '';
    }

    return(
        <div className='chatroomCCC'>
            <div className='chatroomContainer'>
                <div className='header aic frfs'>
                    <i className='bi bi-arrow-left fs24 ml5' onClick={backToBeforePage}></i>
                    <div 
                        className='profilePictureChatroom ml5'
                    ></div>
                    <div className='usernameChatroom ml5'>admin</div>
                </div>
                <div className='body' ref={body}>
                    <div className='layer'></div>
                    {messages.map((index,key)=>{
                        const token = jwtDecode(sessionStorage.getItem('token'));

                        if(index.from === token.username){
                            return(
                                <SendMessage key={key} message={index.message}/>
                            )
                        }else{
                            return(
                                <RecieveMessage key={key} message={index.message}/>
                            )
                        }
                    })}
                </div>
                <form className='footer frc aic' onSubmit={sendingMessage}>
                    <input className='messageInput' type='text' placeholder='message ...'/>
                    <button className='messageBtn' type='submit'>send</button>
                </form>
            </div>
        </div>
    )
}

export default Chatroom;

const SendMessage = ({message}) => {
    const token = jwtDecode(sessionStorage.getItem('token'));
    
    return(
        <div className='frfe w100 mt-15'>
            <div className='sendContainer mr5'>{message}</div>
            <div 
                className='profilePictureInMessageSend mr5'
                style={{
                    backgroundImage:`url(uploads/${token.pictureName})`
                }}
            ></div>
        </div>
    )
}

const RecieveMessage = ({message,username,pictureName}) => {
    return(
        <div className='frfs w100 mt-15'>
            <div className='profilePictureInMessageReceive ml5'></div>
            <div className='recieveContainer ml5'>{message}</div>
        </div>
    )
}